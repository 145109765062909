import '../../styles/mobile_inner_bottom.css'

import '../shared';
import './plugins/main_menu';
// import '../plugins/clappr';
import './../plugins/jquery-oembed-all/jquery.oembed';
import './../plugins/jquery-oembed-all/jquery.oembed.css';

((
    ($) => {

        // let _playerWrapper = $('.video--wrapper');
        // let _parentDiv = _playerWrapper.parent();
        // if (_playerWrapper.length) {
        //     _playerWrapper.each(function () {
        //         let _playerParent = $(this);
        //         let _player = new Clappr.Player({
        //             source: _playerParent.data('src'),
        //             preload: 'auto',
        //             autoPlay: false,
        //             poster: _playerParent.data('poster'),
        //             width: _parentDiv.outerWidth(),
        //             height: (_parentDiv.outerWidth() / 2),
        //             gaAccount: window.ga_id,
        //             gaTrackerName: _playerParent.data('label')
        //         });
        //         _player.attachTo(_playerParent.find('.video--holder')[0]);
        //     });
        // }

        let _description = $('.default-item--desc .embeddedContent');
        if (_description.length) {
            $.each(_description, function (index, el) {
                let text = $(el).data('oembed');
                if (typeof text === 'undefined') {
                    return;
                }
                try {
                    text = text.replace(/(\s|>|^)(https?:[^\s<]*)/igm, '<a href="$2" class="oembed">$2</a>');
                    $(el).html(text)
                    $(el).find('.oembed').oembed(null, {
                        embedMethod: 'auto',
                        apikeys: {
                            // amazon : '<your amazon key>',
                        },
                    });
                } catch (e) {
                    console.error(e)
                }
            })

        }

    }
)(jQuery));